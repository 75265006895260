.main {
  width: 100vw;
  height: 100%;
  background-color: #555;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  text-align: center;
}
.category,
.cat-choose {
  margin: 2rem 0.5rem;
  background-color: #555;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #333;
}
.cat-choose p {
  font-size: 20px;
  color: #fff;
  margin: 1rem;
}

.header-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem 0;
  color: #fff;
}
.read-more {
  background-color: coral;
  color: #fff;
  border-radius: 10px;
  padding: 0.5rem 1.3rem;
}

.loading,
.error {
  width: 100vw;
  height: calc(100vh - 30px - 80px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #555;
  color: #fff;
}

.part-category {
  height: 170px;
  width: 70%;
  margin: 2rem auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.bg-blur {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  filter: blur(5px);
}

.year-and-seasons {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.year-and-seasons div {
  width: 100%;
}
.years {
  height: 70%;
  overflow: scroll;
}
.years::-webkit-scrollbar {
  display: none;
}
.year {
  cursor: pointer;
}
.year-top-margin {
  margin-top: 3rem;
}
.year:last-child {
  margin-bottom: 3rem;
}
.seasons {
  opacity: 1;
  font-size: 15px;
  font-weight: 700;
}
.seasons-disable {
  opacity: 0;
}
.season {
  cursor: pointer;
}

.set-fav-text-color {
  color: #333;
  font-weight: 700;
}
.unset-fav-text-color {
  color: #666;
  font-weight: 700;
}
.fav-text {
  font-size: 15px;
}
.unset-fav {
  font-size: 2rem;
  cursor: pointer;
  margin-top: 0.5rem;
}
.set-fav {
  font-size: 2.5rem;
  color: #333;
  margin-top: 0;
}

/* chose Year */
.set-year {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}
.unset-year {
  font-size: 15px;
  font-weight: 700;
  color: #666;
}
/* chose season */
.set-season {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}
.unset-season {
  font-size: 15px;
  font-weight: 700;
  color: #666;
}

.show-data-disable {
  opacity: 0;
}
.show-data {
  height: calc(100% - 30px - 80px - 170px);
  opacity: 1;
}

@media (max-width: 890px) {
  .main {
    padding-top: 50px;
  }
  .part-category {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .search-form {
    top: 80px;
    left: 50%;
    width: 100vw;
    padding: 0 0 0.5rem;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #555;
}
.home {
  height: auto;
}
.main-container {
  height: 100%;
  display: flex;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
  margin: 10px;
  -ms-overflow-style: none;
}
.cards::-webkit-scrollbar {
  display: none;
}

.card {
  min-width: 270px;
  width: 270px;
  max-height: 330px;
  height: 300px;

  color: #333;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;

  position: relative;
}
.card-img-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.anime-img {
  width: auto;
}
.anime-img img {
  width: 230px;
  max-height: 300px;
  height: 150px;
  transition: 0.3s;
  object-fit: contain;
}
.anime-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.anime-texts p {
  text-align: center;
}
.details {
  opacity: 0;
  transition: all 0.5s;

  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
  color: #fff;
  text-align: left;
  background-color: #555;
  border-radius: 0 0 10px 10px;
}

.hover-item {
  opacity: 0;
  transition: all 0.5s;

  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;

  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title-jp {
  text-align: center;
  color: #fff;
  background-color: #555;
  padding: 0.5rem;
  margin: 0.5rem;
}
.like {
  position: absolute;
  bottom: 0;
  right: 5%;
  transform: translate(-5%, 0);
  font-size: 2rem;
  text-shadow: 0px 0px 1px red;
  color: rgba(51, 51, 51, 1);
  /* z-index: 1; */
  color: #ccc;
  transition: all 0.5s;
}
.liked {
  color: #f7b31c;
}
.card:hover .anime-img img {
  opacity: 0.5;
}
.card:hover .hover-item {
  opacity: 1;
}

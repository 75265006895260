.header {
  position: sticky;
  top: 0;
  width: 100vw;
  height: 80px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
  z-index: 10;
}

.navbar {
  display: flex;
  align-items: center;
}
/* .link {
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
} */
h2 {
  margin: 0.5rem 0 1rem;
}

.hint {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hint-lost-off {
  display: none;
}
.hint-list {
  position: fixed;
  top: 10vw;
  right: 3%;
  width: 600px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: #333;
  padding: 1rem;
  letter-spacing: 0.5px;
  z-index: 3;
}
h4 {
  margin: 10px 0 3px;
}
.explain {
  text-align: left;
  padding: 0 1rem;
}
.explain span {
  color: coral;
  padding-right: 3px;
}
.advice {
  font-size: 13px;
  color: #555;
  padding-left: 5px;
}
.no-bg {
  display: none;
}
.hint-bg {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* --- */

.search-form {
  height: auto;
  align-items: flex-start;
  position: fixed;
  top: 2%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -2%);
}
.search-inputs {
  display: flex;
  align-items: center;
}

.search-lang {
  padding: 5px;
  color: #fff;
  width: 50px;
}
.jp {
  font-size: 10px;
  cursor: pointer;
  color: #fff;
}
.lang-change {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
}

.search-en,
.search-jp {
  font-weight: 900;
  color: coral;
  font-size: 20px;
}
.search-wait {
  font-size: 10px;
}

.search-input {
  height: 40px;
  width: 350px;
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  outline: none;
}
.search-icon {
  height: 40px;
  width: 50px;
  color: #333;
  background-color: #fff;
  display: grid;
  place-items: center;
}
.data-lists {
  width: 400px;
  background-color: #fff;
  border: 1px #333 solid;
  margin-left: 50px;
}
.data-lists .data-item:nth-child(2n) {
  background-color: #eee;
}
.data-lists .data-item {
  width: 100%;
  min-height: 35px;
  height: auto;
  display: flex;
  align-items: center;
  text-align: left;
  color: #333;
}
.data-item:hover,
.data-lists .data-item:nth-child(2n):hover {
  background-color: #999;
}
.data-title {
  margin: 0 20px;
}
.clear-btn {
  cursor: pointer;
}

/* 
  footer 
*/
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #333;
  /* z-index: 3; */
}
.copyright {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media (max-width: 890px) {
  .search-form {
    top: 80px;
    left: 50%;
    width: 100vw;
    padding: 0 0 0.5rem;
  }
  .search-inputs {
    justify-content: center;
    background-color: #555;
  }
  .search-input {
    /* width: 200px; */
  }
  .data-lists {
    margin-right: 50px;
  }
  .hint-list {
    top: 80px;
    right: 10px;
    width: 90vw;
    z-index: 10;
  }
}

@media (max-width: 600px) {
  .search-form {
    top: 80px;
    left: 50%;
    width: 100vw;
    padding: 0 0 0.5rem;
  }
}

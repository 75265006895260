.item {
  width: 95vw;
  min-height: 80vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: auto 0;
  background-color: rgb(240, 248, 255);
  color: #333;
  cursor: default;
  /* z-index: 3; */
}

.item-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 2; */
}

.item-close-btn {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 1.7rem;
  cursor: pointer;
  transition: 0.5s;
}
.item-close-btn:hover {
  transform: rotate(90deg) scale(1.3);
}

.item-titles {
  letter-spacing: 3px;
}

.item-img {
  width: 100%;
  object-fit: contain;
}
.item-img img {
  width: 700px;
  height: auto;
}

@media (max-width: 890px) {
  .item-img img {
    width: 100%;
  }
  .item-img img {
    width: 95vw;
    height: auto;
  }
  .item {
    z-index: 10;
  }
}
